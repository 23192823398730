<template>
  <div>
    <div v-if="isMobileWidth === 1">
      <div class="row">
        <v-col cols="3" elevation="0" max-width="100px" v-for="item in categories" :key="item.name"
          class="text-center" 
        >
          <v-card @click="goToDetail(item.link)" color="transparent" elevation="0">
            <i :class="item.icon" class="text-24 primary--text pb-3"></i><br>
            <div class="text-12">{{ item.name }}</div>
          </v-card>
        </v-col>
      </div>
      <div class="row pt-3">
        <v-col cols="3" elevation="0" max-width="100px" v-for="item in categoriesB" :key="item.name"
          class="text-center"
        >
          <v-card @click="goToDetail(item.link)" color="transparent" elevation="0">
            <i :class="item.icon" class="text-24 primary--text pb-3"></i><br>
            <div class="text-12">{{ item.name }}</div>
          </v-card>
        </v-col>
      </div>
    </div>
    <div v-else>
      <v-card class="round" elevation="0">
        <v-card-title class="pb-3">
          <h1 class="title font-weight-bold black--text mb-0">Kategori Pilihan</h1>
        </v-card-title>
        <div class="row pl-5 pr-5 pb-3 d-flex justify-space-between">
          <!-- <v-col cols="2" v-for="item in categoriesDesk" :key="item.name" class="text-center" 
          > -->
            <v-card class="text-center ma-3" min-width="100px" width="150px" v-for="item in categoriesDesk" :key="item.name" @click="goToDetail(item.link)" outlined>
              <v-card-text>
                <i :class="item.icon" class="text-24 primary--text pb-3"></i><br>
                <div class="text-12 black--text">{{ item.name }}</div>
              </v-card-text>
            </v-card>
          <!-- </v-col> -->
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isMobileWidth:0,
      categories:[
        {name:'TikTok', icon:'fab fa-tiktok', link:'tiktok'},
        {name:'Beauty', icon:'fas fa-spa', link:'beauty'}, 
        {name:'Food & Beverages', icon:'fas fa-hamburger', link:'Food&beverages'},
        {name:'Travel & Lifestyle', icon:'fas fa-plane', link:'travel&lifestyle'},
      ],
      categoriesB:[
        {name:'Parenting', icon:'fas fa-baby', link:'parenting'}, 
        {name:'Home Decor', icon:'fas fa-laptop-house', link:'home-decor'}, 
        {name:'Fashion', icon:'fas fa-tshirt', link:'fashion'}, 
        {name:'Lihat Semua', icon:'fas fa-th-large', link:'category-all'}, 
      ],
      categoriesDesk:[
        {name:'TikTok', icon:'fab fa-tiktok', link:'tiktok'},
        {name:'Beauty', icon:'fas fa-spa', link:'beauty'}, 
        {name:'Food & Beverages', icon:'fas fa-hamburger', link:'Food&beverages'},
        {name:'Travel & Lifestyle', icon:'fas fa-plane', link:'travel&lifestyle'},
        {name:'Parenting', icon:'fas fa-baby', link:'parenting'}, 
        {name:'Home Decor', icon:'fas fa-laptop-house', link:'home-decor'}, 
        {name:'Fashion', icon:'fas fa-tshirt', link:'fashion'}, 
        {name:'Health & Sport', icon:'fas fa-dumbbell', link:'health&sport'},
        {name:'Gamers', icon:'fas fa-gamepad', link:'gamers'}, 
        {name:'Content Creator', icon:'fas fa-camera-retro', link:'content-creator'},
        {name:'Technology', icon:'fas fa-laptop', link:'technology'},
        {name:'Cooking', icon:'fas fa-cookie-bite', link:'cooking'},
      ]
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
    goToDetail(category){
      // this.$router.push('/campaign/choose-influencer'); 
      if(category === 'category-all'){
        this.$router.push({name:'category-all'});
      } else {
        this.$router.push({name:'category-detail', query: {item: category}}); 
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
